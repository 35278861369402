import axios from "./http-interceptoer";

export default {
  // 新增组织
  addOrg(params) {
    return axios.post("/api/client-manage/organization/add", params);
  },
  //获取组织详情
  getOrgById(id) {
    return axios.get("/api/client-manage/organization/get/" + id);
  },
  //获取所管理的组织及食堂详情
  getUserManageOrg(menuId) {
    return axios.get(
      "/api/client-manage/organization/getOrgAndCanteens?menuId=" + menuId
    );
  },
  //组织状态更改
  updateOrgState(params) {
    return axios.put(
      "/api/client-manage/organization/enabed/" + params.id + "/" + params.state
    );
  },
  // 获取组织信息
  getOrgList(params) {
    return axios.get("/api/client-manage/organization/list/page", { params });
  },
  //编辑组织
  updateOrg(params) {
    return axios.put("/api/client-manage/organization/update", params);
  },
  //获取城市
  getProvinceCity(id) {
    return axios.get("/api/client-manage/provinceCity/list/" + id);
  },
  //获取组织已选菜单权限
  getCanteenMenuRelation(id) {
    return axios.get("/api/client-manage/orgMenuRelation/listMenuIds/" + id);
  },
  //组织管理员分页查询
  getOrgAdminList(params) {
    return axios.get("/api/client-manage/user/list/page", { params });
  },
  //新增组织管理员
  addOrgAdmin(params) {
    return axios.post("/api/client-manage/user/add", params);
  },
  //编辑组织管理员
  updateOrgAdmin(params) {
    return axios.put("/api/client-manage/user/update", params);
  },
  //删除组织管理员
  deleteOrgAdmin(id) {
    return axios.delete("/api/client-manage/user/delete/" + id);
  },
  //获取员工信息
  getOrgAdminInfo(id) {
    return axios.get("/api/client-manage/user/get/" + id);
  },
  //查询所有组织
  getAllOrg() {
    return axios.get("/api/client-manage/organization/list");
  }
};
