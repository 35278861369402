<!--大学组件-->
<template>
  <div class="university-container">
    <ul class="university-ul">
      <li
        class="university-li"
        v-for="(item, index) in uniList"
        :key="index"
        :class="index === cur ? 'active' : ''"
        @click="toggle(index, item)"
      >
        <span>{{ item.orgName }}</span>
        <div class="canteen-container" v-if="cur === index">
          <ul class="canteen-ul">
            <li
              class="canteen-li"
              v-for="(cant, _index) in item.canteenVoList"
              :key="_index"
              @click="chooseCanteen(index + '&' + _index, cant)"
              :class="curCanteen === index + '&' + _index ? 'active' : ''"
            >
              <div :class="cant.havePrivilege ? '' : 'ban'">
                <a href="javascript:" :title="cant.canteenName">
                  {{ cant.canteenName }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import api from '@/mock/services/organization.js'

export default {
  props: {
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      uniList: [],
      cur: -1,
      curCanteen: ''
    }
  },
  computed: {
    ...mapState({
      menuId: state => state.menuId,
      curUniversityCanteen: state => state.curUniversityCanteen,
      curUniversityId: state => state.curUniversityId,
      curUniversityCanteenName: state => state.curUniversityCanteenName
    })
  },
  watch: {},
  activated() {
    // this.cur = -1;
    // this.curCanteen = "";
  },
  created() {
    this.getUniversity(this.menuId)
  },
  methods: {
    ...mapMutations(['GETCURUNIVERSITYCANTEEN', 'GETCURUNIVERSITYID']),
    toggle(index, item) {
      this.cur = index
      this.GETCURUNIVERSITYID(item.id)
    },
    chooseCanteen(curCt, canteen) {
      if (!canteen.havePrivilege) return
      this.curCanteen = curCt
      this.GETCURUNIVERSITYCANTEEN({
        canteenId: canteen.canteenId,
        canteenName: canteen.canteenName
      })
    },
    async getUniversity(curMenuId) {
      this.cur = -1
      this.curCanteen = ''
      await api.getUserManageOrg(curMenuId).then(res => {
        if (res && res.success) {
          this.uniList = res.results
          sessionStorage.setItem('UNIVERSITYCANTEENLIST', JSON.stringify(res.results))
          if (this.curUniversityId) {
            let oI = this.uniList.findIndex(x => x.id === this.curUniversityId)
            if (this.curUniversityCanteen) {
              let sI = this.uniList[oI].canteenVoList.findIndex(
                x => x.canteenId === this.curUniversityCanteen
              )
              this.curCanteen = oI + '&' + sI
            }
            this.cur = oI
            this.GETCURUNIVERSITYCANTEEN({
              canteenId: this.curUniversityCanteen,
              canteenName: this.curUniversityCanteenName
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.university-container {
  border: 1px solid #f7f7f7;
  box-shadow: 4px 0 8px 0 rgba(118, 118, 118, 0.1);
  border-radius: 12px;
  padding: 12 / @vw;
  overflow-y: scroll;

  .university-ul {
    width: 220 / @vw;
    //max-height: 100%;
    //overflow-y: scroll;

    .university-li {
      cursor: pointer;
      padding: 8 / @vw 0;
      font-size: 18 / @vw;
      color: #242424;

      &.active {
        span {
          color: #2b9363;

          &:before {
            content: '';
            display: inline-block;
            width: 6 / @vw;
            height: 6 / @vw;
            border-radius: 50%;
            margin-right: 6 / @vw;
            position: relative;
            top: -3px;
            background: #2b9363;
          }
        }
      }

      .canteen-container {
        margin: 10 / @vw 0 0;

        .canteen-ul {
          .canteen-li {
            font-size: 16 / @vw;
            padding: 4 / @vw 0 4 / @vw 28 / @vw;
            line-height: 36 / @vw;

            &.active {
              background: #2b9363;
              border-radius: 8 / @vw;
              color: #fff;

              a {
                color: #fff;
              }
            }

            div {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &.ban {
                cursor: not-allowed;

                a {
                  color: #a5a5a5;
                  cursor: not-allowed;
                }
              }

              a {
                color: #242424;

                &:visited {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
