<template>
  <div>
    <a-auto-complete
      placeholder="搜索食堂..."
      v-model="curCanteenName"
      @select="onSelect"
      @search="handleSearch"
    >
      <template #dataSource>
        <a-select-option v-for="item in dataSource" :key="String(item.id)" :title="item.name">
          {{ item.name }}
        </a-select-option>
      </template>

      <a-input class="search-input">
        <template #suffix>
          <a-icon class="search-icon" type="caret-down" theme="filled" />
        </template>
      </a-input>
    </a-auto-complete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curCanteenName: '',
      dataSource: [], // 模糊查询-食堂数据组
      canteenManager: {} // 全局食堂信息管理器-映射
    }
  },
  activated() {
    if (
      this.canteenManager &&
      this.canteenManager.canteenList &&
      this.canteenManager.canteenList.length > 0
    ) {
      this.curCanteenName = this.canteenManager.canteenList[
        this.canteenManager.curSelectedCanteenIndex
      ].name
    }
  },
  mounted() {
    this.canteenManager = this.$store.state.user.canteenManager
    this.dataSource = this.canteenManager.canteenList
  },
  methods: {
    onSelect(value) {
      let curSelectedCanteenId = Number(value)
      let curSelectedCanteenIndex = -1
      this.canteenManager.canteenList.find((item, index) => {
        if (item.id === curSelectedCanteenId) {
          curSelectedCanteenIndex = index
          return true
        }
      })
      if (
        curSelectedCanteenIndex < 0 ||
        curSelectedCanteenIndex >= this.canteenManager.canteenList.length
      ) {
        console.log('切换食堂失败了')
        return
      }
      this.canteenManager.curSelectedCanteenIndex = curSelectedCanteenIndex
    },
    handleSearch(value) {
      if (typeof value != 'string') {
        return
      }
      if (value.length === 0) {
        this.dataSource = this.canteenManager.canteenList
        return
      }
      this.dataSource = value ? this.searchResult(value) : []
    },
    searchResult(query) {
      return this.canteenManager.canteenList.filter(item => {
        if (typeof item.name === 'string' && item.name.indexOf(query) >= 0) {
          return true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search-icon {
  color: rgb(93, 185, 100);
  font-size: 30 / @vw;
}
</style>
