<template>
  <div class="AIarea">
    <div class="content-top">
      <FsBreadcrumb :routes="routes" />
      <FsAutoComplete v-if="supervisorMode === 2" />
    </div>
    <div class="table">
      <div class="universityBox">
        <UniversityList v-if="supervisorMode === 1" />
        <div class="content">
          <div class="bgItem" v-for="(item, index) in bgList" :key="index" @click="ToBg(item)">
            <span class="tit">{{ item.title }}</span>
            <span class="jr">点击进入</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UniversityList from '@/components/UniversityList/UniversityList'
import FsAutoComplete from '@/components/FsAutoComplete/FsAutoComplete'
import FsBreadcrumb from '@/components/FsBreadcrumb/FsBreadcrumb'
import { mapState } from 'vuex'
export default {
  components: {
    FsAutoComplete,
    FsBreadcrumb,
    UniversityList
  },
  data() {
    return {
      lockPageData: false, // 锁定页面不允变换数据。
      bgList: [
        {
          title: '不明人员',
          path: 'unknownPerson'
        },
        {
          title: '留样中心',
          path: 'bigScreen'
        },
        {
          title: '数据中心',
          path: 'dataCenter'
        },
        {
          title: '智慧运营平台',
          path: 'canteenSelction'
        }
      ],
      routes: [
        {
          path: '/home',
          breadcrumbName: '首页'
        },
        {
          path: '',
          breadcrumbName: '数据中心'
        },
        {
          path: '',
          breadcrumbName: '可视化展示'
        }
      ],
      curCanteenId: ''
    }
  },
  computed: {
    ...mapState({
      supervisorMode: state => state.user.canteenManager.supervisorMode,
      tableHeight: state => state.tableHeight
    })
  },
  watch: {
    '$store.state.curUniversityCanteen'(val) {
      if (this.lockPageData) {
        return
      }
      this.curCanteenId = val
    },
    '$store.state.curUniversityCanteenName'(val) {
      if (this.lockPageData) {
        return
      }
      this.curUniversityCanteenName = val
    }
  },
  activated() {},
  mounted() {
    this.canteenManager = this.$store.state.user.canteenManager
  },
  methods: {
    ToBg(item) {
      let canteenId = ''
      let canteenName = ''
      // 在新窗口打开页面
      if (this.supervisorMode === 1) {
        canteenId = this.curCanteenId
        canteenName = this.curUniversityCanteenName
      } else if (this.supervisorMode === 2) {
        canteenId = this.canteenManager.canteenList[this.canteenManager.curSelectedCanteenIndex].id
        canteenName = this.canteenManager.canteenList[this.canteenManager.curSelectedCanteenIndex]
          .name
      }

      if (!canteenId || canteenId.length == 0 || !canteenName || canteenName.length == 0) {
        this.$info({ title: '请选择食堂' })
        return
      }

      let newWindowObj = this.$router.resolve({
        name: item.path,
        query: {
          canteenId,
          canteenName
        }
      })
      window.open(newWindowObj.href, '_blank')
    }
  },
  beforeRouteLeave(to, from, next) {
    this.lockPageData = true
    next()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/table.less';
.table {
  background-color: transparent !important;
}
.content {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 10px 10px 30 / @vw;
  .bgItem {
    width: 409 / @vw;
    height: 190 / @vw;
    background: #2b9363;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40 / @vw;
    .tit {
      margin-top: 59 / @vw;
      font-size: 24 / @vw;
      font-family: 'puhuiti-medium';
      font-weight: 500;
      color: #242424;
    }
    .jr {
      font-size: 18 / @vw;
      font-weight: 600;
      color: #fff;
    }
    &:hover {
      background: rgba(20, 100, 58, 0.1);
      box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #15663c;
      color: #fff;
    }
  }
}
</style>
